import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Usage`}</h2>
    <p>{`Elevation is used to indicate hierarchy and create focus through the use of depth. Components with the same elevations create an effect of grouping and similarity between content while components on different elevations reflect spatial relationships.
Elevation is depicted through shadows to create an effect of depth. Components closer to the base layer have sharper shadows, while components that are elevated have softer shadows. You can chek out the
guideline for more detail description.`}</p>
    <div className="divi" />
    <h2>{`Preview`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Shape`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Token Name`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="elevation0" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/elevation0`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="elevation1" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/elevation1`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="elevation2" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/elevation2`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="elevation3" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/elevation3`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="elevation4" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/elevation4`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="elevation5" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`?attr/elevation5`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`android: elevation = "?attr/elevation0";
android: elevation = "?attr/elevation1";
android: elevation = "?attr/elevation2";
android: elevation = "?attr/elevation3";
android: elevation = "?attr/elevation4";
android: elevation = "?attr/elevation5";
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      